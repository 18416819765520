body#homepage {
    .search-wrapper {
        .search-box-triggers {
            color: color('text', 'dark');

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .select2-container--default {
        .select2-selection--multiple {
            .select2-search {
                width: 100%;
            }
        }
    }

    .select2-container--default .select2-search--inline .select2-search__field {
        width: 100% !important;
        margin-top: 0;
    }
}

.homepage-search {
    .SumoSelect {
        &.sumo_type {
            .CaptionCont {
                display: block !important;
            }
        }
    }
}

body#homepage {
    .search-selects {
        .input-column {
            input {
                border: 1px solid #e5e5e5;
            }
        }
    }
}

.search-selects {
    background: transparent;
    padding: 0;
    color: color('primary');
    font-size: 14px;

    .input-column {
        height: 50px;
        padding-left: 0;

        input {
            padding: 0 15px;
            height: 50px;
            width: 100%;
        }
    }

    .locations-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;

        .select2-container {
            border: 1px solid #e5e5e5;
            height: 50px;
        }
    }

    .select-column {
        width: 141px;
        height: 50px;
        padding-left: 0;

        .SumoSelect {
            > .CaptionCont {
                border: 1px solid #e5e5e5;
                margin-right: 1px;
                height: 50px;
                text-align: left;

                > span {
                    height: 50px;
                    line-height: 50px;

                    &.placeholder {
                        color: #1b262e;
                        font-size: 14px;
                        font-style: normal;
                    }
                }

                > label {
                    > i {
                        line-height: 50px;
                    }
                }
            }

            > .optWrapper {
                top: 51px;
                border: none;

                .options {
                    li {
                        text-align: left;

                        label {
                            position: relative;
                            top: -1px;
                        }

                        &.opt {
                            padding-left: 30px !important;

                            span {
                                width: 20px;
                                margin-left: -25px;
                            }
                        }
                    }
                }
            }
        }
    }

    .input-group {
        .input-group-append {
            .btn {
                width: 50px;
                background: color('primary');
                color: #FFF !important;
                border: 1px solid color('primary');

                &:hover {
                    color: color('primary');
                    background: #FFF;
                }
            }
        }
    }
}