.box-margin {
    margin-bottom: 2rem !important;
}

.property-card {
    border: 1px solid #d4d4d4;
    border-radius: 0;

    .card-heading {
        position: relative;

        &::after {
            content: " ";
            height: 59px;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            bottom: 0;
            z-index: 2;
        }

        .card-gallery {
            overflow: hidden;

            .slick-nav {
                font-size: 30px;
                color: #FFF;
                position: absolute;
                top: 50%;
                margin-top: -16px;
                padding: 0 1.25rem;
                z-index: 9;
                text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);

                &.slick-prev {
                    left: 0;
                }

                &.slick-next {
                    right: 0;
                }
            }
        }

        .card-img-overlay-top {
            top: 15px;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
            z-index: 3;
            position: absolute;
            left: 17px;
            background-color: #0087ea;

            p {
                margin: 5px 10px 5px;
                font-size: 12px;
                color: #ffff;
                text-transform: uppercase;
                font-weight: 400;
            }

            span {
                font-weight: 800;
            }
        }

        .card-img-overlay {
            bottom: 0;
            top: unset;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
            z-index: 3;

            ul {
                margin: 0;

                li {
                    font-size: 12px;
                    line-height: 2;
                    font-weight: 700;
                    color: #FFF;
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                    padding-right: 10px;
                    margin-right: 6px;

                    &:last-of-type {
                        border: 0;
                        padding-right: 0;
                        margin-right: 0;
                    }

                    &#ground-plans-icon {
                        background-image: url("../../images/icons/ground-plan-white.png");
                        background-position: left center;
                        background-repeat: no-repeat;
                        border-radius: 0;
                        background-size: 14px 14px;
                        padding-left: 15px;
                    }

                    span {
                        margin-left: 5px;
                    }

                    i {
                        font-size: 14px;
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }

        .add-to-link-list,
        .add-to-favorites {
            font-size: 20px;
            color: #FFF;
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 1.25rem;

            i {
                font-weight: 400;
                transition: all .3s ease-in;
            }

            @media (min-width: 992px) {
                &:not(.adding) {
                    &:hover {
                        i {
                            color: color('error');
                            font-weight: 900;
                            text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.5);
                        }
                    }
                }
            }

            &:hover,
            &.added {
                i {
                    color: color('error') !important;
                    font-weight: 900;
                    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.5);
                }
            }
        }
    }

    .incognito-property-indicator {
        font-size: 20px;
        color: #FFF;
        position: absolute;
        right: 0;
        top: 0;
        padding: 1.25rem;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, .5);
    }

    .card-body {
        padding-bottom: 0;

        h2 {
            font-size: 18px;
            color: #1b262e;
            height: auto;
            overflow: hidden;

            @media (min-width: 768px) {
                height: 42px;
            }
        }

        a {
            color: #555 !important;
        }

        p {
            font-size: 14px;
            font-weight: 300;
            color: #444444;
            margin: 0;

            strong {
                font-weight: 600;
            }
        }

        ul {
            margin: 0;

            li {
                font-size: 13px;
                font-weight: 600;
                color: #444444;
                margin-right: 0.6rem;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .card-footer {
        padding: 0;

        .price {
            font-size: 19px;
            font-weight: 600;
            color: #555;
            margin: 0;

            a {
                color: #ffffff;
                font-size: 26px;

                &:hover {
                    text-decoration: none;
                }
            }

            small {
                text-decoration: line-through;
            }
        }
    }
}

ul {
    &.property-details {
        .property-id-icon {
            background-image: url("../../images/icons/property-id.png");
            background-repeat: no-repeat;
            background-position: left 2px;
            padding-left: 19px;

            &.white {
                background-image: url("../../images/icons/property-id-white.png");
            }
        }

        .property-bed-icon:before,
        .property-bathroom-icon:before,
        .property-area-icon:before,
        .property-floor-icon:before,
        .property-rooms-icon:before,
        .property-lux-patch:before,
        {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            background-color: color('primary');
            mask-size: cover;
            margin-right: 6px;
            position: relative;
            top: 4px;
        }

        .property-bed-icon:before {
            mask: url("../../images/icons/svg/bedroom.svg") no-repeat 100% 100%;
        }

        .property-lux-patch:before {
            background-image: url("../../images/icons/lux-patch.png");
            padding-left: 40px;

            &.white {
                background-image: url("../../images/icons/lux-patch-white.png");
            }
        }

        .property-bathroom-icon:before {
            mask: url("../../images/icons/svg/bathroom.svg") no-repeat 100% 100%;
        }

        .property-area-icon:before {
            mask: url("../../images/icons/svg/area.svg") no-repeat 100% 100%;
        }

        .property-floor-icon:before {
            mask: url("../../images/icons/svg/floor.svg") no-repeat 100% 100%;
        }

        .property-rooms-icon:before {
            mask: url("../../images/icons/svg/rooms.svg") no-repeat 100% 100%;
        }
    }
}

.properties-container {
    .property-details {
        margin-top: 1rem !important;
    }
}

/* CSS for version 2*/
.property-card {
    &.v2,
    &.v4,
    &.v6 {
        .card-body {
            padding-bottom: 0;
        }

        .card-footer {
            padding: 1rem 1.25rem 1.25rem;

            .price {
                a {
                    font-size: 19px;
                    font-weight: 600;
                    color: rgb(0, 0, 0);

                    &:hover {
                        color: #525252;
                        text-decoration: none;
                    }
                }

                small {
                    text-decoration: line-through;
                }
            }
        }
    }
}

#saving-search-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    &.modal-hide {
        display: none;
    }

    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        border: 1px solid rgba(color('primary'), 0.3);
        width: 450px;

        @media(max-width: 768px) {
            width: 370px;
        }

        @media(max-width: 576px) {
            width: 330px;
        }

        .modal-top {
            padding: 20px;
            background-color: color('primary');
            color: #eee;

            p {
                margin: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 300;
                width: 49.99%;
                line-height: 2;
            }

            .close {
                color: #fff;

                span {
                    font-size: 40px;
                }
            }

            span {
                width: 49.99%;
                text-align: end;

                i {
                    font-size: 20px;
                    line-height: 1.5;
                    cursor: pointer;
                }
            }
        }

        .modal-form {
            padding: 20px;

            label {
                font-size: 15px;
            }

            .input-wrapper {
                position: relative;

                input {
                    padding: 8px;
                    margin-bottom: 15px;
                    width: 100%;
                }

                &::after {
                    position: absolute;
                    z-index: 2;
                    top: -8px;
                    right: -5px;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    font-size: 9px;
                    content: "\f005";
                    color: #222222;
                }
            }

            select {
                padding: 10px;
                margin-bottom: 15px;
                width: 100%;
            }

            .modal-save-btn {
                padding: 10px 18px;
                text-transform: uppercase;
                color: #fff;
                background-color: color('primary');
                font-size: 15px;
                font-weight: 300;

                &[disabled] {
                    background: #8896b0;
                }

                &[disabled]:hover {
                    cursor: default;
                }

                i {
                    padding-left: 10px;

                    &::before {
                        content: "\f0c7";
                    }
                }
            }

            .modal-save-search-bottom {
                margin-top: 10px;

                span {
                    line-height: 3;

                    i {
                        position: relative;
                        padding: 0 5px;

                        &:before {
                            position: absolute;
                            top: -13px;
                            left: 0;
                            content: "\f005";
                            font-size: 9px;
                            color: color('primary');
                        }
                    }
                }
            }
        }
    }

    #modal-success-message {
        padding: 20px 20px 0 20px;
        font-size: 15px;
        color: #222222;

        a {
            color: #222222;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}

.saving-search-modal-message {
    background-color: #d4edda;

    &.alert {
        position: fixed !important;
        margin-left: auto;
        transform: translate(-50%, -50%);
    }

    &.alert-error {
        background-color: #ef6464;
    }

    span {
        font-size: 15px;
    }

    strong {
        &.value {
            line-break: anywhere;
        }
    }

    a {
        font-weight: 700;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 30px;
        color: #ffffff;

        &:hover {
            color: #eeee;
        }
    }
}


/* CSS for version 5 */
.special-offer-wrapper {
    display: flex;
    align-items: center;
}

.property-card {
    &.v5 {
        border: none;
        height: 700px;

        @media (max-width: 1265px) {
            align-items: center;
        }

        @media (max-width: 992px) {
            width: auto;
            height: auto;
        }

        .old-price {
            text-decoration: line-through;
            margin-top: 2px;
            margin-left: 5px;
        }

        .card-heading {
            &::after {
                background: none;
            }
        }

        .card-gallery {
            overflow: hidden;

            @media (min-width: 772px) {
                height: 495px;
            }

            img {
                @media (max-width: 992px) {
                    height: auto;
                    width: 100%;
                }
            }

            .slick-nav {
                font-size: 30px;
                color: #FFF;
                position: absolute;
                top: 50%;
                margin-top: -16px;
                padding: 0 1.25rem;
                z-index: 9;

                &.slick-prev {
                    left: 0;
                }

                &.slick-next {
                    right: 0;
                }
            }
        }

        .card-body {
            padding: 25px;
            min-width: 400px;
            background-color: white;
            position: absolute;
            z-index: 9;
            transform: translateY(-60%);
            margin-left: 35px;
            left: 0;

            @media (max-width: 475px) {
                min-width: 290px;
            }

            .card-text {
                font-family: font(main);
                font-size: 24px;

                @media (max-width: 992px) {
                    font-size: 22px;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: color('text', 'dark');
                margin: 0;
                text-align: left !important;

                strong {
                    font-weight: 600;
                }
            }

            ul {
                margin: 0;
                margin-top: 10px !important;

                li {
                    font-size: 15px;
                    font-weight: 400;
                    color: color('text', 'darker');
                    margin: 0 10px 0 0;
                    border-left: 1px solid color('text', 'darker');
                    padding-left: 10px;

                    @media (max-width: 992px) {
                        font-size: 15px;
                    }

                    &:first-of-type {
                        border-left: 0;
                        padding-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    .price {
                        justify-content: center;
                        display: flex;
                        font-size: 15px;
                        color: color('text', 'darker');
                    }
                }

                &.property-action {
                    text-transform: uppercase;
                    margin: 5px 0 0;

                    li {
                        color: color('text', 'dark');
                        border: 0;
                    }
                }
            }
        }

        .property-text-section {
            color: color('text', 'light');
            left: 0;
            width: 620px;

            p {
                color: color('text', 'light');
            }
        }
    }
}

.property-card {
    &.v6 {
        border: 0;

        .card-footer {
            padding: 1.25rem 0 0;

            .price {
                a {
                    font-size: 21px;
                    font-weight: 400;
                    color: color('text', 'dark');
                }
            }
        }

        .card-body {
            padding-left: 0;

            h2 {
                color: color('text', 'dark');

                a {
                    color: color('text', 'dark') !important;
                }
            }

            p {
                font-size: 15px;
                color: color('text', 'light');

                &.card-text {
                    span {
                        margin-left: 20px;
                    }
                }
            }

            ul {
                li {
                    color: color('text', 'dark');
                    font-weight: 400;
                }
            }
        }
    }
}

.btn-special-offer {
    font-size: 15px;
    text-transform: uppercase;
    color: color('text', 'light') !important;
    border: 1px solid color('border', 'dark');
    padding: 10px 25px;

    @media (max-width: 992px) {
        padding: 10px 50px;
    }

    @media (max-width: 362px) {
        padding: 10px 20px;
    }

    &:hover {
        border: 1px solid color('white');
    }
}

.properties-home-wrap {
    .properties-home-title {
        margin-bottom: 50px;

        @media (max-width: 992px) {
            font-size: 30px;
            margin: 0;
        }
    }

    h2 {
        color: color('text', 'dark');
        font-weight: normal;
    }

    .properties-home-arrows {
        padding-top: 10px;

        @media (max-width: 992px) {
            margin-bottom: 20px;
        }

        i {
            font-size: 25px;
            color: color('text', 'dark');
        }
    }

    .property-text-section {
        position: relative;
        top: 85px;
        left: 34px;
        width: 600px;
        height: 150px;
        overflow: hidden;
        font-size: 16px;
        line-height: 1.8;
        color: color('text', 'dark');

        @media (max-width: 992px) {
            width: unset;
            height: 345px;
            left: 0;
            margin: 0 15px 0 15px;
        }

        @media (max-width: 475px) {
            top: 120px;
        }

        p {
            font-size: 16px;
            line-height: 1.8;
            color: color('text', 'dark');
            text-align: left !important;
            display: inline;
        }
    }
}

.slick-slide {
    @media (max-width: 992px) {
        padding: 0;
    }
}